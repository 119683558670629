<template>
    <v-app>
        <NavBar style="max-height: 50px; margin-bottom: 10px"></NavBar>
        <div class="row">
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-2">
                <SideBar style="max-height: 50px" :pageName="pageName"/>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-10">
                <div v-if="isHeroBannerDataLoading && isSegmentDataLoading" class="d-flex justify-center align-center" style="margin-top:20%">
                    <v-progress-circular size="30" indeterminate color="primary"></v-progress-circular>
                </div>
                <div v-else class="scrollDiv">
                    <div class="flexRowBetween" style="height: 48px;margin:24px;">
                        <div class="commonHomeFont">
                            <p>
                                ePharmacy Home
                            </p>
                        </div>
                        <div class="mtba"
                        :style="(!isHeroBannerAvailable || isSegmentAvailable) ? 'background: #BDBDBD;': 'background: #219653; color: #FFFFFF;'"
                        style="
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 20px;
                        gap: 10px;
                        width: 218px;
                        height: 48px;
                        border-radius: 4px;
                        flex: none;
                        cursor: pointer;
                        order: 1;
                        flex-grow: 0;"
                        @click="previewOnBuilder()"
                        >
                            <img :src="previewBanner"/>
                            <p style="margin-top:auto; margin-bottom: auto;"> 
                                Preview On Builder
                            </p>
                        </div>
                    </div>
                    
                    <div class="heroBannerClass" style="margin:24px;">
                        <div class="flexRowBetween" style="height: 47px; width: 100%;"> 
                            <div class="d-flex"> 
                                <div class="mr-4">
                                    <img :src="heroBanner"/>
                                </div>
                                <div class="d-flex flex-column">
                                    <span class="heroBannerFontClass">Hero Banner</span>
                                    <span>Upload hero banners for web and mobile</span>
                                </div>
                            </div>
                            <div 
                            style="
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            padding: 10px 16px;
                            gap: 10px;
                            width: 152px;
                            height: 36px;
                            background: #48ACEF;
                            border-radius: 4px;
                            flex: none;
                            cursor: pointer;
                            order: 1;
                            flex-grow: 0;"
                            @click="addHeroBanner()"
                            >
                                <img :src="multipleAdds"/>
                                <span style="color: #FFFFFF"> 
                                    Add Banner
                                </span>
                            </div>
                        </div>
                        <div v-if="isHeroBannerAvailable" style="border: 1px solid #E0E0E0; width: 100%; min-height: 171px;">
                            <TableComponent
                                :tableItem="heroBannerDetails" 
                                :tableTitle="tableHeaders" 
                                :headerColor="headerColor"
                                :customeSlotName="customeSlotName"
                                @actionClicked="callRespectiveAPI"
                                @switchClicked="callRespectiveAPI"
                            />
                        </div>
                        <div v-else 
                        class="flexJustifyAlignCenter"
                        style="height: 171px; width: 100%; border: 1px solid #E0E0E0;border-radius: 8px;">
                            <div class="d-flex flex-column">
                                <img style="height:60px;" :src="notInCloud" />
                                <p style="text-align:left">No banners added yet.</p>
                            </div>
                        </div>
                    </div>
                    <div class="addSegmentClass addSegmentClassscrollDiv" style="margin:24px;">
                        <div class="flexRowBetween" style="width: 100%; height: 47px;">
                            <div class="d-flex flex-column" style="width: 78%"> 
                                <span style="width: 143px;
                                height: 24px;
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 20px;
                                line-height: 24px;
                                color: #333333;
                                flex: none;
                                order: 0;
                                flex-grow: 0;">Add Segments</span>
                                <span style="text-align: left;">Create and update page sections here</span>
                            </div>
                            <div class="addNewSegmentsDiv"> 
                                <div class="mx-2 px-2"
                                    style="
                                    display: flex; flex-direction: row;
                                    cursor: pointer;
                                    border: 1px solid #F89035;
                                    border-radius: 4px;"
                                    @click="openBannerSegment()">
                                    <p style="
                                        font-family: 'Inter';
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 16px;
                                        line-height: 19px;
                                        color: #F89035;
                                        margin:auto;"
                                        >
                                        Banners
                                    </p>
                                    <img style="margin:auto; height: 24px; width: 24px;" :src="addBanner" />
                                </div>
                                <div class="px-2"
                                    style="
                                    display: flex; flex-direction: row;
                                    cursor: pointer;
                                    border: 1px solid #219653;
                                    border-radius: 4px;"
                                    @click="openBlockSegment()">
                                    <p style="
                                        margin:auto;
                                        font-family: 'Inter';
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 16px;
                                        line-height: 19px;
                                        color: #219653;"
                                        >
                                        Blocks
                                    </p>
                                    <img style="margin:auto; height: 24px; width: 24px;" :src="addBlock" />
                                </div>
                                <div class="mx-2 px-2"
                                    style="
                                    display: flex; flex-direction: row;
                                    cursor: pointer;
                                    border: 1px solid #AB6BFB;
                                    border-radius: 4px;"
                                    @click="openArticleSegment()"
                                >
                                    <p style="
                                        margin:auto;
                                        font-family: 'Inter';
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 16px;
                                        line-height: 19px;
                                        color: #AB6BFB;"
                                        >
                                        Articles
                                    </p>
                                    <img style="margin:auto; height: 24px; width: 24px;" :src="addArticles" />
                                </div>
                            </div>
                        </div>
                        <div class="dashedLineClass"></div>
                        <div v-if="!isSegmentAvailable" style="margin-right:24px; width: 100%;">
                            <draggable v-model="items" v-bind="dragOptions" :move="checkMove(items)">
                                <div v-for="item in items" :key="item.id" class="d-flex">
                                    <div>
                                        <img :src="draggableIcon" />
                                    </div>
                                    <div class="draggable-item bannerBlockArticleCollapsibleClass mb-6">
                                            <div class="innerSpanBannerBlockArticleFontClass"
                                            :style="item.type === 'Banner' ? 
                                            'color: #F89035; background: #FFE8D3;' 
                                            : item.type === 'Block' ? 'color: #34C873; background: #DAEEE8;' : 'color: #AB6BFB; background: #F0E4FF;'"
                                            > 
                                            {{ item.type }}
                                            </div>
                                            <div v-if="item.type === 'Banner'">
                                                <span class="innerSpanFont">Banner Title:</span>
                                                <span class="innerSpanBoldFont"> {{ item.bannerTitle }}</span>
                                                <span class="innerSpanFont">Banner Type:</span>
                                                <span class="innerSpanBoldFont"> {{ item.bannerType }}</span>
                                                <span class="innerSpanFont">Total Banners:</span>
                                                <span class="innerSpanBoldFont"> {{ item.totalBannerCount }}</span>
                                            </div>
                                            <div v-if="item.type === 'Block'">
                                                <span class="innerSpanFont">Block Title:</span>
                                                <span class="innerSpanBoldFont">{{ item.blockTitle }}</span>
                                                <span class="innerSpanFont">Block Title:</span>
                                                <span class="innerSpanBoldFont"> {{item.blockType}}, Multiple ({{item.totalBlockCount}}) </span>
                                            </div>
                                            <div v-if="item.type === 'Article'">
                                                <span class="innerSpanFont">Article Title:</span>
                                                <span class="innerSpanBoldFont"> {{ item.articleTitle }} </span>
                                            </div>

                                            <div class="d-flex ml-2" style="cursor: pointer;">
                                                <img class="mr-2" :src="editIcon" @click="openEditInnerSegment(item)"/>
                                                <img class="mr-2" :src="deleteIcon" @click="deleteInnerSegment(item)"/>
                                            </div>
                                    </div>
                                </div>
                            </draggable>
                        </div>
                        <div v-else-if="isSegmentAvailable && !(showPromotionBanners || showBlock || showArticles)" class="flexJustifyAlignCenter" 
                        style="border: 1px solid #E0E0E0; width: 100%; height: 305px;border-radius: 12px;">
                            <div class="d-flex flex-column">
                                <img style="height: 96.78px;" :src="noSegmentImage" />
                                <p>No segments added yet.</p>
                            </div>
                        </div>
                        <div  class="d-flex" style="margin-right:24px; width: 97%">
                            <div v-if="showCommonDraggableIcon">
                                <img :src="draggableIcon" />
                            </div>
                            <div class="bannerBlockArticleClass" v-if="showPromotionBanners"> 
                                <div style="width:100%; max-height: 1610px;">
                                    <UploadBannerComponent
                                    :pageType="pageType"
                                    :promoBannerNameList="promoBannerName"
                                    :orderNumber="orderNumber"
                                    :editBannerFlagProps="editFlag"
                                    :editBannerDetails="editFlag ? editBannerDetails : {}"
                                    @discardAddBannerDialog="commonDiscardEmitFunction"
                                    @addBannerResponseEmit="commonAddUpdateResponseEmit"
                                    @updateBannerResponseEmit="commonAddUpdateResponseEmit"
                                    />
                                </div>
                            </div>
                            <div class="bannerBlockArticleClass" v-if="showBlock"> 
                                <div style="width:100%;">
                                    <AddBlockComponent 
                                    :pageType="pageType"
                                    :promoBlockNameList="promoBlockName"
                                    :iconObjects="iconObjects"
                                    :editBlockFlagProps="editFlag"
                                    :editBlockDetails="editBlockDetails"
                                    :orderNumber="orderNumber"
                                    @discardAddBlockDialog="commonDiscardEmitFunction"
                                    @updateBlockResponseEmit="commonAddUpdateResponseEmit"
                                    @addBlockResponseEmit="commonAddUpdateResponseEmit"
                                    />
                                </div>
                            </div>
                            <div class="bannerBlockArticleClass" v-if="showArticles"> 
                                <div style="width:100%;">
                                    <AddArticleComponent 
                                    :pageType="pageType"
                                    :promoArticleNameList="promoArticleName"
                                    :editArticleFlagProps="editFlag"
                                    :iconObjects="iconObjects"
                                    :orderNumber="orderNumber"
                                    :editArticleDetails="editArticleDetails"
                                    @discardAddArticleDialog="commonDiscardEmitFunction"
                                    @updateArticleResponseEmit="commonAddUpdateResponseEmit"
                                    @addArticleResponseEmit="commonAddUpdateResponseEmit"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="addHeroBannerDialogFlag">
                        <AddHeroBannerDialog 
                        :pageType="pageType"
                        :addHeroBannerDialogProps="addHeroBannerDialogFlag" 
                        :editHeroBannerDialogProps="editHeroBannerDialogFlag" 
                        :finalEditValue="finalEditValue"
                        @updateHeroBannerRecord="updateHeroBannerRecord"
                        @closeHeroBannerDialog="closeHeroBannerDialog"
                        @closeCancelOperation="closeCancelOperation"/>
                    </div>
                    <div v-if="showPreviewOnBuilder">
                        <PreviewOnBuilderDialogComponentVue :previewOnBuilderProps="previewOnBuilderProps" 
                        @closePreviewDialogOnSuccess="closePreviewDialogOnSuccess"
                        @closePreviewDialog="closePreviewDialog"/>
                    </div>
                    <div v-if="confirmationFlag">
                        <DeleteConfirmationDialog :confirmationFlag="confirmationFlag" :itemType="itemType" @closeDialog="closeDialog" />
                    </div>
                    <div v-if="showMessageDialog">
                        <SuccessDialog :successObject="successObject" @closeSuccessDialog="closeSuccessDialog"/>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
import axios from "axios";
import{ axios_auth_instance_epharmacy_admin } from '../../../utils/axios_utils';
import draggable from 'vuedraggable';
import NavBar from '../../../views/navbar.vue';
import SideBar from '../../../views/sideBar.vue';
import AddHeroBannerDialog from '../../../components/AddHeroBannerDialogComponent.vue';
import UploadBannerComponent from '../../../components/UploadBannerComponent.vue';
import AddBlockComponent from '../../../components/AddBlockComponent.vue';
import AddArticleComponent from '../../../components/AddArticleComponent.vue';
import DeleteConfirmationDialog from "../../../components/DeleteConfirmationDialog.vue";
import SuccessDialog from "../../../components/SuccessDialog.vue";
import PreviewOnBuilderDialogComponentVue from '../../../components/PreviewOnBuilderDialogComponent.vue'
export default {
name: "EHP",
components : {
    TableComponent: () => import("../../../components/tableComponent.vue"),
    'NavBar': NavBar,
    'SideBar': SideBar,
    'AddHeroBannerDialog': AddHeroBannerDialog,
    'draggable': draggable,
    'UploadBannerComponent': UploadBannerComponent,
    'AddBlockComponent': AddBlockComponent,
    'DeleteConfirmationDialog': DeleteConfirmationDialog,
    'SuccessDialog': SuccessDialog,
    'PreviewOnBuilderDialogComponentVue': PreviewOnBuilderDialogComponentVue,
    'AddArticleComponent': AddArticleComponent,
},
data() {
    return {
        pageType: "EPHARMACY",
        pageName: 'EHP',
        successObject: {},
        showMessageDialog: false,
        itemType:'',
        promoBannerName: [],
        promoBlockName: [],
        promoArticleName: [],
        orderNumber: 0,
        iconObjects: [],
        segmentCacheData: [],
        promotionBanners: [],
        specializations: [],
        showPreviewOnBuilder: false,
        previewOnBuilderProps: {},
        bannerEditData: {},
        editBlockDetails: {},
        confirmationFlag: false,
        getBannerSegmentResponseData: {},
        editArticleDetails:{},
        items: [],
        dragOptions: {
            animation: 200
        },
        addHeroBannerDialogFlag: false,
        cloudFront: process.env.VUE_APP_CLOUDFRONT_URL_FOR_IMAGES,
        editIcon: 'editIcon.svg',
        deleteIcon: 'deleteIcon.svg',
        heroBanner: 'heroBanner.svg',
        notInCloud: 'notInCloud.svg',
        draggableIcon: 'draggableIcon.svg',
        noSegmentImage: 'noSegment.svg',
        addBanner: 'addBanner.svg',
        finalEditFlag: false,
        editHeroBannerDialogFlag: false,
        finalEditValue: {},
        multipleAdds: 'multipleAdds.svg',
        addBlock: 'addBlock.svg',
        previewBanner: 'previewBanner.svg',
        addArticles: 'addArticles.svg',
        isHeroBannerAvailable: false,
        isSegmentAvailable: true,
        editFlag: false,
        showPromotionBanners: false,
        showCommonDraggableIcon: false,
        showBlock: false,
        showArticles: false,
        bannerTypes: ['Carousal', 'Single'],
        bannerType: '',
        tableHeaders: [
            //{ text: 'S.No.', value: 's_no', sortable: false},
            { text: 'Hero Banner Title', value: 'heroBannerName', sortable: false},
            { text: 'Start Date', value: 'heroBannerStartDate', sortable: false },
            { text: 'End Date', value: 'heroBannerExpireDate', sortable: false },
            { text: 'Availability Status', value: 'availableStatus', sortable: false },
            { text: 'Status', value: 'status', sortable: false },
            { text: 'Actions', value: 'actions', class:'actionClass', sortable: false}
        ],
        headerColor: `#F4F6FA !important`,
        heroBannerDetails: [],
        customeSlotName: [],
        isHeroBannerDataLoading: true,
        isSegmentDataLoading: true,
    }
},
methods: {
    closeSuccessDialog() {
        this.showMessageDialog = false;
    },
    scrollToElement() {
        this.$nextTick(() => {
            var scrollContainer = document.querySelector(".scrollDiv");
            if(scrollContainer) {
                var scrollHeight = scrollContainer.scrollHeight - 1500;
                scrollContainer.scrollTop = scrollHeight;
            }
        });
    },
    checkMove(items) {
        if (items.length == this.totalSegmentLength) {
            this.reOrderingCall(items);
        }
    },
    async reOrderingCall(items) {
        let rearrangeData = [];
        for(let [ind, item] of items.entries()) {
            let segmentOrderNumber = ind + 1;
            if(item['segmentOrder'] != segmentOrderNumber) {
                rearrangeData.push({
                    pk: item.pk,
                    sk: item.sk,
                    segmentOrder: segmentOrderNumber
                })
            }
        }
        try {
            if(rearrangeData && rearrangeData.length > 0) {

                let res = await axios_auth_instance_epharmacy_admin.post("/admin/segmentReorder", {
                    rearrangeData: rearrangeData,
                    pageType: this.pageType
                });
                if(res && res.data && res.data.data && res.data.data.length > 0) {
                    for(let [ind, item] of this.items.entries()) {
                        for(let [jind, jitem] of res.data.data.entries()) {
                            if(item['sk'] == jitem['sk']) {
                               item['segmentOrder'] = jitem['segmentOrder'];
                            }
                        }
                        for(let [jind, jitem] of this.segmentCacheData.entries()) {
                            if(item['sk'] == jitem['key']) {
                                jitem['segment_order'] = item['segmentOrder'];
                            }
                        }
                    }
                } else {
                    // this.$cookies.remove('token');
                    // this.$router.push({
                    //     name: "AdminLogin",
                    // });
                }
            } else {
                console.log('NO REORDERING');
            }
        } catch (error) {
            if(error && error.response && error.response.status == 401) {
                window.alert("Sorry !! You are unauthorized !!");
                this.$cookies.remove('token');
                this.$router.push({
                    name: "AdminLogin",
                });
            }
        }
    },
    async previewOnBuilder() {
        await this.makeCacheData();
    },
    async makeCacheData() {
        this.previewOnBuilderProps['showPreviewOnBuilder'] = true;
        this.previewOnBuilderProps['heroBannerCacheData'] = this.heroBannerCacheData;
        this.previewOnBuilderProps['segmentCacheData'] = this.segmentCacheData;
        this.previewOnBuilderProps['specializations'] = this.specializations;
        this.previewOnBuilderProps['homepageType'] = 'EPHARMACY';
        this.showPreviewOnBuilder = true;
    },
    async getAllHeroBanners() {
        try {
            let getAllHeroBannersResponse = await axios_auth_instance_epharmacy_admin.post("/admin/getAllHeroBanners", {
                    pageType: this.pageType
                });
            let res = getAllHeroBannersResponse;
            if(res && res.data && res.data.data && res.data.data) {
                this.heroBannerDetails = [];
                this.heroBannerCacheData = [];
                for (let item of res.data.data) {
                    let statusValue = 'ACTIVE';
                    let currentTime = Date.now();
                    if(item.display_data.startDateEpoch > currentTime) {
                        statusValue = 'INACTIVE';
                    } else if(item.display_data.endDateEpoch < currentTime) {
                        statusValue = 'EXPIRED';
                    }
                    this.heroBannerDetails.push({
                        'shortID': item.short_id,
                        'heroBannerName': item.display_data.heroBannerName,
                        'heroBannerStartDate': item.display_data.heroBannerStartDate,
                        'heroBannerExpireDate':  item.display_data.heroBannerExpireDate,
                        'heroBannerWebImageName': item.display_data.heroBannerWebImageName,
                        'heroBannerMobileImageName': item.display_data.heroBannerMobileImageName,
                        'heroBannerWebImageURL': item.display_data.heroBannerWebImageURL,
                        'heroBannerMobileImageURL': item.display_data.heroBannerMobileImageURL,
                        'jumpLink': item.display_data.jumpLink,
                        'bannerPriority': item.display_data.bannerPriority,
                        'availableStatus': item.display_data.activeStatus,
                        'status': statusValue,
                        'actions': '',
                    });
                    if (item.display_data.activeStatus && item.display_data.heroBannerExpireDateEpoch > Date.now()) {
                        this.heroBannerCacheData.push({
                            'banner_priority': item.display_data.bannerPriority,
                            'end_date': item.display_data.endDateEpoch,
                            'filename_desktop':item.display_data.heroBannerWebImageURL,
                            'filename_mobile': item.display_data.heroBannerMobileImageURL,
                            'route_to': item.display_data.jumpLink,
                            'start_date': item.display_data.startDateEpoch,
                            'availableStatus': item.display_data.activeStatus,
                        });
                    }
                    this.collapseAddHeroBannerDiv = true;
                    this.isHeroBannerAvailable = true;
                    this.isHeroBannerDataLoading = false;
                }
            } else {
                this.isHeroBannerDataLoading = false;
                // this.$cookies.remove('token');
                // this.$router.push({
                //     name: "AdminLogin",
                // });
            }
            await this.renderTableComponent();
        } catch (error) {
            if(error && error.response && error.response.status == 401) {
                window.alert("Sorry !! You are unauthorized !!");
                this.$cookies.remove('token');
                this.$router.push({
                    name: "AdminLogin",
                });
            }
        }
    },
    async renderTableComponent() {			
        let activeStatus = {
            'headerValue':'availableStatus',
            'ComponenetType': 'vSwitch',
            'objectToRender': [
                {
                    'functionToEmit': 'changeAvailableStatus'
                }
            ]
        }
        let actionButtons = {
            'headerValue':'actions',
            'ComponenetType': 'button',
            'objectToRender': [
                {
                    'iconSrc' : "https://s3iconimages.mymedicine.com.mm/edit.svg",
                    'functionToEmit': 'openEditHeroBannerDialog'
                },
                {
                    'iconSrc' : "https://s3iconimages.mymedicine.com.mm/delete.svg",
                    'functionToEmit': 'deleteHeroBanner'
                }
            ],
        };
        this.customeSlotName.push(activeStatus, actionButtons);
    },
    callRespectiveAPI(functionName, rowObject) {
        this[functionName](rowObject);
    },
    async updateHeroBannerRecord() {
        this.addHeroBannerDialogFlag = false;
        this.editHeroBannerDialogFlag = false;
        await this.getAllHeroBanners();
        await this.renderTableComponent();
    },
    async changeAvailableStatus(rowObject) {
        let changeAvailableStatusData = {
            pageType: this.pageType,
			shortID: rowObject.shortID,
            activeStatus: rowObject.availableStatus
        }
        try {
            let changeAvailableStatusResponse = await axios_auth_instance_epharmacy_admin.post("/admin/changeAvailableStatusForHeroBanner", changeAvailableStatusData)
            if(changeAvailableStatusResponse && changeAvailableStatusResponse.data && changeAvailableStatusResponse.data.data) {
                this.successObject['showMessageDialog'] = true;
                this.successObject['publishSuccess'] = false;
                this.successObject['messageText'] = "Hero banner's active status got changed successfully";
                this.showMessageDialog = true;
            } else {
                // this.$cookies.remove('token');
                // this.$router.push({
                //     name: "AdminLogin",
                // });
            }
        } catch (error) {
            if(error && error.response && error.response.status == 401) {
                window.alert("Sorry !! You are unauthorized !!");
                this.$cookies.remove('token');
                this.$router.push({
                    name: "AdminLogin",
                });
            }
        }
        this.isLoading = false;
    },
    async openEditHeroBannerDialog(rowObject) {
        this.editHeroBannerDialogFlag = true;
        this.finalEditFlag = false;
        let getHeroBannerData = {
            pageType: this.pageType,
			shortID: rowObject.shortID
        }
        try {
            let res = await axios_auth_instance_epharmacy_admin.post("/admin/getParticularHeroBanner", getHeroBannerData);
            if(res && res.data && res.data.data && res.data.data.Items && res.data.data.Items.length > 0) {
                let heroBannerData = res.data.data.Items[0];
                this.finalEditValue = heroBannerData;
                this.addHeroBannerDialogFlag = true;
            } else {
                // this.$cookies.remove('token');
                // this.$router.push({
                //     name: "AdminLogin",
                // });
            }
        } catch (error) {
            if(error && error.response && error.response.status == 401) {
                window.alert("Sorry !! You are unauthorized !!");
                this.$cookies.remove('token');
                this.$router.push({
                    name: "AdminLogin",
                });
            }
        }
    },
    async getAvailableProductsByCategories(parentCategoryID, childList) {
        try {
            let res = await axios_auth_instance_epharmacy_admin.post("/admin/getAvailableProductsByCategories", {
                parentCategoryID: parentCategoryID
            })
            if(res && res.data && res.data.data && res.data.data.Items && res.data.data.Items.length > 0) {
                let toGetCategory = res.data.data.Items;
                this.collectionPreviewListItems = [];
                for (let ind in toGetCategory) {
                    if(childList.includes(toGetCategory[ind].product_id)) {
                        this.collectionPreviewListItems.push({
                            'product_id': toGetCategory[ind].product_id,
                            'product_name' : toGetCategory[ind].product_name,
                            'product_icon_image': toGetCategory[ind].product_icon_image,
                            'description': toGetCategory[ind].description,
                            'list_price': toGetCategory[ind].list_price,
                        });
                    }
                }
            } else {
                // this.$cookies.remove('token');
                // this.$router.push({
                //     name: "AdminLogin",
                // });
            }
        } catch (error) {
            if(error && error.response && error.response.status == 401) {
                window.alert("Sorry !! You are unauthorized !!");
                this.$cookies.remove('token');
                this.$router.push({
                    name: "AdminLogin",
                });
            }
        }
    },
    async getAllSegments() {
        try {
            let getAllSegmentsResponse = await axios_auth_instance_epharmacy_admin.post("/admin/getAllSegments", {
                pageType: this.pageType
            });
            this.items = [];
            this.totalSegmentLength = 0;
            let res = getAllSegmentsResponse;
            if (res && res.data && res.data.data && res.data.data.length > 0) {
                this.totalSegmentLength = res.data.data.length;
                this.isSegmentAvailable = false;
                for(let [ind, item] of res.data.data.entries()) {
                    if(item.segmentType === 'Block') {
                        item.blockTitle = item.display_data.blockTitleEN;
                        this.promoBlockName.push(item.blockTitle);
                        item.blockType = item.display_data.blockType;
                        item.totalBlockCount = item.display_data.collectionList.length;
                        this.items.push(item);
                        let insideItems = [];
                        if(item.display_data.blockType == "Doctors") {
                            for(let i of item.display_data.collectionList) {
                                this.collectionPreviewListItems = [];
                                let specializationList = [];
                                specializationList.push(i.collectionItemValue);
                                let queryParams = {
                                    token: this.$cookies.get('token'),
                                    typeOfUser: "ADMIN",
                                    specializationList: JSON.stringify(specializationList)
                                };
                                let varItem = await axios.get(process.env.VUE_APP_BACKEND_URL + "/getDoctorsBySpecializationList", {
                                    params: queryParams
                                });
                                if(varItem && varItem.data && varItem.data.data && varItem.data.data && varItem.data.data.length > 0) {
                                    let toGetCategory = varItem.data.data;
                                    for (let ind in toGetCategory) {
                                        if((i.collectionPreviewList).includes(toGetCategory[ind]._id)) {
                                            this.collectionPreviewListItems.push({
                                                'id': toGetCategory[ind]._id,
                                                'doctorId': toGetCategory[ind].doctor_id,
                                                'name' : toGetCategory[ind].doctor_name,
                                                'icon': toGetCategory[ind].doctor_profile_picture,
                                                'description': toGetCategory[ind].specialist,
                                                'price': parseInt(toGetCategory[ind].service_fee) + parseInt(toGetCategory[ind].commission),
                                            });
                                        }
                                    }
                                    insideItems.push({
                                        'items': this.collectionPreviewListItems,
                                        "tab_link": i.collectionItemValue,
                                        "tab_name": i.collectionItemValueName
                                    });
                                }    
                            }
                        }
                        if(item.display_data.blockType == "Products") {
                            for(let i of item.display_data.collectionList) {
                                this.collectionPreviewListItems = [];
                                let varItem =  await axios_auth_instance_epharmacy_admin.post("/admin/getAvailableProductsByCategories", {
                                    parentCategoryID: i.collectionItemValue
                                });
                                if(varItem && varItem.data && varItem.data.data && varItem.data.data.Items && varItem.data.data.Items.length > 0) {
                                    let toGetCategory = varItem.data.data.Items;
                                    for (let ind in toGetCategory) {
                                        if((i.collectionPreviewList).includes(toGetCategory[ind].product_id)) {
                                            this.collectionPreviewListItems.push({
                                                'product_id': toGetCategory[ind].product_id,
                                                'product_name' : toGetCategory[ind].product_name,
                                                'product_icon_image': toGetCategory[ind].product_icon_image,
                                                'description': toGetCategory[ind].description,
                                                'list_price': toGetCategory[ind].list_price,
                                            });
                                        }
                                    }
                                    insideItems.push({
                                        'items': this.collectionPreviewListItems,
                                        "tab_link": i.collectionItemValue,
                                        "tab_name": i.collectionItemValueName
                                    });
                                } else {
                                    // this.$cookies.remove('token');
                                    // this.$router.push({
                                    //     name: "AdminLogin",
                                    // });
                                }
                            }
                        }
                        if(item.display_data.blockType == "Collections") {
                            for(let i of item.display_data.collectionList) {
                                this.collectionPreviewListItems = [];
                                let collectionList = [];
                                let sortOrderString = '{"sortField":"alphabetical","sortValue":"asc"}';
                                collectionList.push(i.collectionItemValue);
                                let sortOrder = JSON.parse(sortOrderString);
                                let varItem =  await axios_auth_instance_epharmacy_admin.get("/admin/getAvailableProductsByCollections", {
                                    params:{
                                            collectionList: collectionList,
                                            minAmount: Number(0),
                                            maxAmount: Number(1000000),
                                            sortOrder: sortOrder
                                        }
                                });
                                if(varItem && varItem.data && varItem.data.data) {
                                    let toGetCategory = varItem.data.data;
                                    for (let ind in toGetCategory) {
                                        if((i.collectionPreviewList).includes(toGetCategory[ind].product_id)) {
                                            this.collectionPreviewListItems.push({
                                                'product_id': toGetCategory[ind].product_id,
                                                'product_name' : toGetCategory[ind].product_name,
                                                'product_icon_image': toGetCategory[ind].product_icon_image,
                                                'description': toGetCategory[ind].description,
                                                'list_price': toGetCategory[ind].list_price,
                                            });
                                        }
                                    }
                                    insideItems.push({
                                        'items': this.collectionPreviewListItems,
                                        "tab_link": i.collectionItemValue,
                                        "tab_name": i.collectionItemValueName
                                    });
                                } else {
                                    // this.$cookies.remove('token');
                                    // this.$router.push({
                                    //     name: "AdminLogin",
                                    // });
                                }
                            }
                        }
                        if(item.status == "ACTIVE") {
                            this.segmentCacheData.push({
                                "background": item.display_data.backgroundColor,
                                "items": insideItems,
                                "icon": item.display_data.selectedIconURL,
                                "item_type": (item.blockType).toUpperCase(),
                                "displayJumpLink": item.display_data.displayJumpLink,
                                "custom_url_flag": item.display_data.displayJumpLinkDetails.customURLFlag,
                                "catalog_page_type":item.display_data.displayJumpLinkDetails.jumpLink == "CatalogPage" ? item.display_data.displayJumpLinkDetails.catalogPageType : '',
                                "custom_url": item.display_data.displayJumpLinkDetails.customURLFlag ? item.display_data.displayJumpLinkDetails.customURL : {},
                                "redirection": item.display_data.displayJumpLinkDetails.jumpLink,
                                "redirection_label": item.display_data.displayJumpLinkDetails.buttonTitleEN,
                                "redirection_label_burmese": item.display_data.displayJumpLinkDetails.buttonTitleMM,
                                "segment_order": item.segmentOrder,
                                "title": item.blockTitle,
                                "key": item.sk,
                                "type": "LISTINGS-ONE-ROW"
                            });
                        }
                    }
                    if(item.segmentType === 'Banner') {
                        console.log('Banner item', item);
                        item.bannerTitle = item.display_data.bannerName;
                        this.promoBannerName.push(item.bannerTitle);
                        item.bannerType = item.display_data.bannerType;
                        item.totalBannerCount = item.display_data.bannerDetails.length;
                        this.items.push(item);
                        this.promotionBanners = [];
                        for(let i of item.display_data.bannerDetails) {
                            if(i.activeStatus) {
                                this.promotionBanners.push({
                                    'banner_priority': i.bannerPriority,
                                    'end_date': i.bannerExpireDate,
                                    'filename_desktop':i.desktopBannerImageURL,
                                    'filename_mobile': i.mobileBannerImageURL,
                                    'route_to': i.jumpLink,
                                    'start_date': i.bannerStartDate,
                                    'availableStatus': i.activeStatus,
                                })
                            }
                        }
                        this.isSegmentAvailable = false;
                        this.segmentCacheData.push({
                            "items": this.promotionBanners,
                            "key": item.sk,
                            "segment_order": item.segmentOrder,
                            "type": "BANNERS-ONE-PER-SLIDE"
                        });
                    }
    
                    if(item.segmentType === 'Article') {
                        item.articleTitle = item.display_data.articleTitleEN;
                        this.promoArticleName.push(item.articleTitle);
                        this.items.push(item);
                        this.isSegmentAvailable = false;
                        let insideItems = [];
                        for(let [index, article] of item.display_data.articleData.entries()) {
                            insideItems.push({
                                "id": article.id,
                                "img_url": `${article.articleImageURL}`,
                                "title": article.articleName,
                                "link": article.articleLink,
                            });
                        }
                        if(item.status == "ACTIVE") {
                            this.segmentCacheData.push({
                                "background": item.display_data.backgroundColor,
                                "items": insideItems,
                                "icon": item.display_data.selectedIconURL,
                                "segment_order": item.segmentOrder,
                                "displayJumpLink": item.display_data.displayJumpLink,
                                "redirection": item.display_data.displayJumpLinkDetails.jumpLink,
                                'custom_url_flag': item.display_data.displayJumpLinkDetails.customURLFlag,
                                "catalog_page_type":item.display_data.displayJumpLinkDetails.jumpLink == "CatalogPage" ? item.display_data.displayJumpLinkDetails.catalogPageType : '',
                                'custom_url': item.display_data.displayJumpLinkDetails.customURLFlag ? item.display_data.displayJumpLinkDetails.customURL : {},
                                "redirection_label": item.display_data.displayJumpLinkDetails.buttonTitleEN,
                                "redirection_label_burmese": item.display_data.displayJumpLinkDetails.buttonTitleMM,
                                "title": item.articleTitle,
                                "key": item.sk,
                                "type": "ARTICLES"
                            });
                        }
                    }
                }
                this.orderNumber = this.items.length + 1;
                this.isSegmentDataLoading = false;
                await this.renderTableComponent();
            } else {
                this.isSegmentDataLoading = false;
                this.isSegmentAvailable = true;
                // this.$cookies.remove('token');
                // this.$router.push({
                //     name: "AdminLogin",
                // });
            }
        } catch (error) {
            if(error && error.response && error.response.status == 401) {
                window.alert("Sorry !! You are unauthorized !!");
                this.$cookies.remove('token');
                this.$router.push({
                    name: "AdminLogin",
                });
            }
        }        
    },
    openEditInnerSegment(item) {
        if(item.type === 'Banner') this.getParticularBannerSegment(item.short_id, item.bannerType);
        if(item.type === 'Block') this.getParticularBlockSegment(item.short_id);
        if(item.type === 'Article') this.getParticularArticleSegment(item.short_id);
    },
    deleteInnerSegment(item) {
        this.itemType = item.type;
        if(item.type === 'Banner') {
            this.shortID = item.short_id;
            this.deleteBannerType = item.bannerType;
        }
        if(item.type === 'Block') {
            this.shortID = item.short_id;
        }
        if(item.type === 'Article') {
            this.shortID = item.short_id;
        }
        this.confirmationFlag = true;
    },
    async deleteParticularArticleSegment() {
        try {
            let deleteArticleResponse =  await axios_auth_instance_epharmacy_admin.post("/admin/deleteArticle", {
                shortID: this.shortID,
                pageType: this.pageType
            })
            if(deleteArticleResponse && deleteArticleResponse.data && deleteArticleResponse.data.message === 'SUCCESS') {
                this.shortID = '';
                this.refreshPage();
            } else {
                // this.$cookies.remove('token');
                // this.$router.push({
                //     name: "AdminLogin",
                // });
            }
        } catch (error) {
            if(error && error.response && error.response.status == 401) {
                window.alert("Sorry !! You are unauthorized !!");
                this.$cookies.remove('token');
                this.$router.push({
                    name: "AdminLogin",
                });
            }
        }
    },
    async deleteParticularBlockSegment() {
        try {
            let deleteBlockResponse = await await axios_auth_instance_epharmacy_admin.post("/admin/deleteBlock", {
                    shortID: this.shortID,
                    pageType: this.pageType
            });
            if(deleteBlockResponse && deleteBlockResponse.data && deleteBlockResponse.data.message === 'SUCCESS') {
                this.shortID = '';
                this.refreshPage();
            } else {
            //  this.$cookies.remove('token');
            //  this.$router.push({
            //      name: "AdminLogin",
            //  });
            }
        } catch (error) {
            if(error && error.response && error.response.status == 401) {
                window.alert("Sorry !! You are unauthorized !!");
                this.$cookies.remove('token');
                this.$router.push({
                    name: "AdminLogin",
                });
            }
        }
    },
    async deleteParticularBannerSegment() {
        try {
            let deleteBannerResponse = await axios_auth_instance_epharmacy_admin.post("/admin/deleteBanner", {
                shortID: this.shortID,
                pageType: this.pageType,
                bannerType: this.deleteBannerType
            });
            if(deleteBannerResponse && deleteBannerResponse.data && deleteBannerResponse.data.message === 'SUCCESS') {
                this.shortID = '';
                this.deleteBannerType = '';
                this.refreshPage();
            } else {
                // this.$cookies.remove('token');
                // this.$router.push({
                //     name: "AdminLogin",
                // });
            }
        } catch (error) {
            if(error && error.response && error.response.status == 401) {
                window.alert("Sorry !! You are unauthorized !!");
                this.$cookies.remove('token');
                this.$router.push({
                    name: "AdminLogin",
                });
            }
        }
    },
    async commonDiscardEmitFunction() {
        this.showPromotionBanners = false;
        this.showArticles = false;
        this.showBlock = false;
        this.isSegmentAvailable = false;
        this.showCommonDraggableIcon = false;
    },
    commonAddUpdateResponseEmit(value) {
        this.showPromotionBanners = false;
        this.showArticles = false;
        this.showBlock = false;
        this.isSegmentAvailable = false;
        this.refreshPage();
    },
    refreshPage() {
        window.location.reload();
    },
    addHeroBanner() {
        this.addHeroBannerDialogFlag = true;
    },
    deleteHeroBanner(rowObject) {
        this.shortID = rowObject.shortID;
        this.confirmationFlag = true;
        this.itemType = 'Hero Banner';
    },
    async deleteHeroBannerMethodCall() {
        try {
            let deleteHeroBannerData = {
                shortID: this.shortID,
                pageType: this.pageType,
            }
            let res = await axios_auth_instance_epharmacy_admin.post("/admin/deleteHeroBanner", deleteHeroBannerData);
            if(res && res.status === 200) {
                this.refreshPage();
            } else {
                this.isLoading = false;
                // this.$cookies.remove('token');
                // this.$router.push({
                //     name: "AdminLogin",
                // });
            }
        } catch (error) {
            if(error && error.response && error.response.status == 401) {
                window.alert("Sorry !! You are unauthorized !!");
                this.$cookies.remove('token');
                this.$router.push({
                    name: "AdminLogin",
                });
            }
        }
    },
    closePreviewDialogOnSuccess() {
        this.showPreviewOnBuilder = false;
        this.successObject['showMessageDialog'] = true;
        this.successObject['publishSuccess'] = true;
        this.successObject['messageText'] = "Epharmacy Home Page published successfully";
        this.showMessageDialog = true;
    },
    closePreviewDialog(val) {
        this.showPreviewOnBuilder = false;
        this.showMessageDialog = false;
    },
    closeDialog (valueFromEmit) {
        if (valueFromEmit.value) {
            if(valueFromEmit.itemType === 'Banner') this.deleteParticularBannerSegment();
            if(valueFromEmit.itemType === 'Block') this.deleteParticularBlockSegment();
            if(valueFromEmit.itemType === 'Article') this.deleteParticularArticleSegment();
            if(valueFromEmit.itemType === 'Hero Banner') this.deleteHeroBannerMethodCall();
        } else {
            this.confirmationFlag = false;
        }
    },
    async closeHeroBannerDialog(value) {
        this.addHeroBannerDialogFlag = false;
        this.editHeroBannerDialogFlag = false;
        await this.getAllHeroBanners();
        await this.renderTableComponent();
    },
    closeCancelOperation() {
        this.addHeroBannerDialogFlag = false;
        this.editHeroBannerDialogFlag = false;
    },
    async makeDefaultEditSection() {
        this.showCommonDraggableIcon = false;
        this.showPromotionBanners = false;
        this.showBlock = false;
        this.editFlag = false;
        this.showArticles = false;
    },
    async getParticularBannerSegment(shortID, bannerType) {
        // this.bannerEditData = item;
        await this.makeDefaultEditSection();
        try {
            let getParticularBannerSegmentResponse = await axios_auth_instance_epharmacy_admin.post("/admin/getParticularBannerSegment", {
                bannerType: bannerType,
                shortID: shortID,
                pageType: this.pageType
            });
            if(getParticularBannerSegmentResponse && getParticularBannerSegmentResponse.data && getParticularBannerSegmentResponse.data.data) {
                this.editBannerDetails = getParticularBannerSegmentResponse.data.data;
                this.showCommonDraggableIcon = true;
                this.showPromotionBanners = false;
                this.showArticles = false;
                this.showBlock = false;
                this.editFlag = true;
                this.showPromotionBanners = true;
                if (this.showPromotionBanners) {
                    this.scrollToElement();
                }
            } else {
                // this.$cookies.remove('token');
                // this.$router.push({
                //     name: "AdminLogin",
                // });
            }
            await this.renderTableComponent();
        } catch (error) {
            if(error && error.response && error.response.status == 401) {
                window.alert("Sorry !! You are unauthorized !!");
                this.$cookies.remove('token');
                this.$router.push({
                    name: "AdminLogin",
                });
            }
        }
    },
    async openBannerSegment() {
        await this.commonDiscardEmitFunction();
        this.editFlag = false;
        this.showArticles = false;
        this.showBlock = false;
        this.showCommonDraggableIcon = true;
        this.showPromotionBanners = true;
        if (this.showPromotionBanners) {
            this.scrollToElement();
        }
    },
    async getParticularBlockSegment(shortID) {
        await this.makeDefaultEditSection();
        axios_auth_instance_epharmacy_admin
        .post("/admin/getParticularBlockSegment", {
            shortID: shortID,
            pageType: this.pageType
        }).then(async (getParticularBlockSegmentResponse) => {
            if(getParticularBlockSegmentResponse && getParticularBlockSegmentResponse.data && getParticularBlockSegmentResponse.data.data) {
                this.editBlockDetails = getParticularBlockSegmentResponse.data.data;
                this.showCommonDraggableIcon = true;
                this.showPromotionBanners = false;
                this.showArticles = false;
                this.editFlag = true;
                this.showBlock = true;
                if (this.showBlock) {
                    this.scrollToElement();
                }
            }
            await this.renderTableComponent();
        }).catch((getParticularBlockSegmentError) => {
            console.error('getParticularBlockSegmentError', getParticularBlockSegmentError);
            if (getParticularBlockSegmentError.response.status == 401) {
                window.alert("Sorry !! You are unauthorized !!");
                this.$cookies.remove('token');
                this.$router.push({
                    name: "AdminLogin",
                });
            }
        });
    },
    async getParticularArticleSegment(shortID) {
        await this.makeDefaultEditSection();
        try {
            let getParticularArticleSegmentResponse = await axios_auth_instance_epharmacy_admin.post("/admin/getParticularArticleSegment", {
                shortID: shortID,
                pageType: this.pageType
            });
            if(getParticularArticleSegmentResponse && getParticularArticleSegmentResponse.data && getParticularArticleSegmentResponse.data.data) {
                this.editArticleDetails = getParticularArticleSegmentResponse.data.data;
                this.showCommonDraggableIcon = true;
                this.showPromotionBanners = false;
                this.showBlock = false;
                this.editFlag = true;
                this.showArticles = true;
                if (this.showArticles) {
                    this.scrollToElement();
                }
            } else {
                // this.$cookies.remove('token');
                // this.$router.push({
                //     name: "AdminLogin",
                // });
            }
            await this.renderTableComponent();
        } catch (error) {
            if(error && error.response && error.response.status == 401) {
                window.alert("Sorry !! You are unauthorized !!");
                this.$cookies.remove('token');
                this.$router.push({
                    name: "AdminLogin",
                });
            }
        }
    },
    async openBlockSegment() {
        await this.commonDiscardEmitFunction();
        this.showCommonDraggableIcon = true;
        this.showPromotionBanners = false;
        this.showArticles = false;
        this.showBlock = true;
        if (this.showBlock) {
            this.scrollToElement();
        }
    },
    async openArticleSegment() {
        await this.commonDiscardEmitFunction();
        this.showCommonDraggableIcon = true;
        this.showPromotionBanners = false;
        this.showBlock = false;
        this.showArticles = true;
        if (this.showArticles) {
            this.scrollToElement();
        }
    },
    async getObjectsFromIconFolder() {
        try {
            let iconData = await axios_auth_instance_epharmacy_admin.post("/admin/getObjectsFromIconFolder", {
                iconFolderName: 'iconFolder'
            });
            if(iconData && iconData.data && iconData.data.data) {
                this.iconObjects = iconData.data.data;
            } else {
                // this.$cookies.remove('token');
                // this.$router.push({
                //     name: "AdminLogin",
                // });
            }
        } catch (error) {
            if(error && error.response && error.response.status == 401) {
                window.alert("Sorry !! You are unauthorized !!");
                this.$cookies.remove('token');
                this.$router.push({
                    name: "AdminLogin",
                });
            }
        }
    }
},
async mounted() {
    this.currentUser = this.$cookies.get("token");
    if (!this.currentUser) {
        this.$router.push({
        name: "AdminLogin",
        });
    } else {
        this.heroBanner = this.cloudFront + this.heroBanner;
        this.previewBanner = this.cloudFront + this.previewBanner;
        this.notInCloud = this.cloudFront + this.notInCloud;
        this.noSegmentImage = this.cloudFront + this.noSegmentImage;
        this.multipleAdds = this.cloudFront + this.multipleAdds;
        this.addBanner = this.cloudFront + this.addBanner;
        this.addBlock = this.cloudFront + this.addBlock;
        this.addArticles = this.cloudFront + this.addArticles;
        this.draggableIcon =  this.cloudFront + this.draggableIcon;
        this.editIcon = this.cloudFront + this.editIcon;
        this.deleteIcon = this.cloudFront + this.deleteIcon;
        await this.getAllHeroBanners();
        await this.getAllSegments();
        await this.renderTableComponent();
        await this.getObjectsFromIconFolder();
        try {
            let categoriesResponse = await axios_auth_instance_epharmacy_admin.post("/admin/getAllCategories", {});
            if (categoriesResponse && categoriesResponse.data && categoriesResponse.data.data && categoriesResponse.data.data.Items && categoriesResponse.data.data.Items.length > 0) {
                let categories = categoriesResponse.data.data.Items;
                for (let category of categories) {
                    this.specializations.push({
                        '_id': category.cat_id,
                        'name': category.cat_name,
                        'image_filename': category.cat_icon,
                    });
                }
            } else {
                // this.$cookies.remove('token');
                // this.$router.push({
                //     name: "AdminLogin",
                // });
            }
        } catch (error) {
            if(error && error.response && error.response.status == 401) {
                window.alert("Sorry !! You are unauthorized !!");
                this.$cookies.remove('token');
                this.$router.push({
                    name: "AdminLogin",
                });
            }
        }
    }
},
}
</script>

<style>
.addNewSegmentsDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.scrollDiv {
    max-height: 950px;
    overflow-y: scroll;
}
.dot {
display: flex;
align-items: center;
width: 80px;
height: 80px;
background-color: #000;
border-radius: 50%;
margin: 10px;
cursor: grab;
color: #fff;
}

.dot-handle {
width: 20px;
height: 20px;
background-color: #fff;
border-radius: 50%;
margin-right: 10px;
cursor: grab;
}
.draggable-item {
background-color: #f2f2f2;
border: 1px solid #ddd;
padding: 10px;
margin-bottom: 5px;
cursor: grab;
width: 100%;
}

.flexRowBetween {
display: flex;
flex-direction: row;
justify-content: space-between;
}
.commonHomeFont{
height: 34px;
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 34px;
color: #333333;
flex: none;
order: 0;
flex-grow: 0;
}
.heroBannerClass {
box-sizing: border-box;
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 24px;
gap: 32px;
isolation: isolate;
background: #FFFFFF;
border: 1px solid #E0E0E0;
border-radius: 12px;
flex: none;
order: 2;
align-self: stretch;
flex-grow: 0;
}
.mtb20 {
margin-top: 20px;
margin-bottom: 20px;
}
.heroBannerFontClass{
width: 121px;
height: 24px;
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 24px;
color: #48ACEF;
flex: none;
order: 0;
flex-grow: 0;
}
.addSegmentClass {
box-sizing: border-box;
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 24px;
gap: 24px;
background: #FFFFFF;
border: 1px solid #E0E0E0;
border-radius: 12px;
flex: none;
order: 2;
align-self: stretch;
flex-grow: 1;
}
.dashedLineClass {
height: 0px;
border: 1px dashed #BDBDBD;
flex: none;
align-self: stretch;
flex-grow: 0;
}
.flexJustifyAlignCenter {
display: flex;
justify-content: center;
align-items: center;
}
.bannerBlockArticleClass {
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 2px 8px 8px 8px;
    flex: none;
    order: 1;
    flex-grow: 1;
    width: 100%;
}
.noUnderLineClass {
text-decoration: none !important;
}
.bannerBlockArticleCollapsibleClass{
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: space-between;
padding: 16px;
gap: 24px;
width: 90%;
height: 64px;
background: #FFFFFF;
border: 1px solid #E0E0E0;
box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
border-radius: 2px 8px 8px 8px;
flex: none;
order: 1;
flex-grow: 1;
}
.innerSpanBannerBlockArticleFontClass {
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 4px 24px;
gap: 10px;
width: 96px;
height: 32px;
border-radius: 4px;
flex: none;
order: 0;
flex-grow: 0;
}
.innerSpanFont {
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
color: #828282;
flex: none;
order: 0;
flex-grow: 0;
}
.innerSpanBoldFont {
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
color: #333333;
flex: none;
order: 1;
flex-grow: 0;
}
/* CSS are added since the inbuild class are not working  */
.row {
  display: flex !important;
  flex-wrap: wrap !important;
}
.d-flex {
  display: flex !important;
}

.justify-space-between {
  justify-content: space-between !important;
}
.flex-column {
  flex-direction: column !important;
}
.mr-4 {
  margin-right: 4px;
}
.mx-2 {
  margin-left: 2px;
  margin-right: 2px;
}
.my-2 {
    margin-top: 4px;
    margin-bottom: 4px;
}
.px-2 {
  padding-left: 2px;
  padding-right: 2px;
}
.mb-6 {
  margin-bottom: 6px;
}
.mr-2 {
  margin-right: 2px;
}
.ml-2{
    margin-left: 2px;
}
.d-none {
    display: none !important;
}
</style>
